@import
url('https://fonts.googleapis.com/css2?family=Kalam&family=Merriweather&family=Montserrat:ital,wght@0,300;0,500;1,300&display=swap');

.home{
    font-family: 'Kalam', cursive;
    padding-bottom: 10%;
}

.about{
    max-width: 1000px;
    font-size: 18px;
}