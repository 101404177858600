@import url('https://fonts.googleapis.com/css2?family=Kalam&family=Merriweather&family=Montserrat:ital,wght@0,300;0,500;1,300&display=swap');

.cart{
  font-family: 'Kalam', cursive;
  
}
.cart ul{
    width: 100%;
}
.cart li{
    background-color: #D9D9D9;
    padding: 1%;
    border-radius: 3%;
}

.cart p{
    color: black;
}
.cart a{
    text-decoration: none;
}

.cart img{
    max-width:15%;
    max-height: 20%;
}
